<template>
  <NoNavLayout noside>
    <div class="d-flex justify-content-center align-items-center px-3 py-5 h-100">
      <Wrapper center noinfo contain normal>
        <div class="d-flex flex-column align-items-center mb-3 px-2">
          <TrialPopup v-if="finishedRegistration && clientData.isTrial" />
          <v-container v-if="finishedRegistration">
            <v-row>
              <v-col>
                <h2 v-if="clientData.isTrial">
                  {{ $t("global.yourTrialIsReady") }}
                </h2>
                <h2 v-else>{{ $t("global.youRegisteredSuccessfully") }}</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p v-if="clientData.isTrial">
                  {{ $t("global.checkEmailForTrial") }}
                </p>
                <p>{{ $t("global.willGetEmailWithPass") }}</p>
              </v-col>
            </v-row>
            <v-col>
              <a :href="newPlayerLink" target="_blank">
                <button type="submit" class="btn btn-danger px-3" style="color: #fff;">
                  <span>{{ $t("global.startWatching") }}</span>
                </button>
              </a>
            </v-col>
          </v-container>
          <v-container v-else-if="(clientData.phone && clientData.isTrial) || chooseToGoWithTrial
            ">
            <div class="verify-container">
              <h1 class="verify-header">{{ $t("verification.headerVerification") }}</h1>
              <div v-if="mode === 'whatsapp'" class="verify-subtext">
                <p class="verify-subtext-item">{{ $t("verification.subtext1Verification") }}
                  <WhatsApp />
                </p>
                <p class="verify-subtext-item2">{{ $t("verification.subtext2Verification") }}</p>
              </div>
              <div class="verify-input-el">
                <input type="number" v-model="code" :placeholder='$t("verification.placeholderVerification")'
                  class="verify-input">
                <button :class="!code || code.length < 6 || code.length > 6 || verifyCounter > 3 ? 'disabled-btn' : ''"
                  type="submit" class="verify-btn" @click="verify">{{ $t("verification.btnTextVerification") }}</button>
              </div>
              <p v-if="verificationError">{{ verificationError }}</p>
              <p v-if="verifyCounter > 3">{{ $t("global.triedToMany") }}</p>
              <p class="verify-question">{{ $t("verification.questionVerification") }}</p>
              <button class="verify-sms-btn" @click="switchToSMS">{{ $t("verification.getSms") }}</button>
            </div>
            <v-row>
              <v-col>
                <recaptcha />
                <button :disabled="timeUntilNextResend > 0" type="submit" :class="timeUntilNextResend > 0
                  ? 'btn btn-sm btn-outline-danger px-3'
                  : 'btn btn-sm btn-danger px-3'
                  " @click="resendCode" v-if="mode === 'sms'">
                  <span v-if="timeUntilNextResend > 0">{{ $t("global.resendIn") }} {{ timeUntilNextResend }}</span>
                  <span v-else>{{ $t("global.resend") }}</span>
                </button>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-if="showAreYouSureNoTrial" class="d-flex flex-column align-items-center mb-3 px-2">
            <h2>{{ $t("global.oneStepForFreeTrial") }}</h2>
            <v-row>
              <v-col>
                <button type="submit" class="btn btn-sm btn-danger px-3" @click="resendCode(true)">
                  <span>{{ $t("global.getFreeTrial") }}</span>
                </button>
                <button type="submit" class="btn btn-sm btn-outline-danger px-3" @click="verify">
                  <span>{{ $t("global.passFreeTrial") }}</span>
                </button>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </Wrapper>
    </div>
  </NoNavLayout>
</template>

<script>
import { mapActions } from "vuex";
import ApiService from "../../services/ApiService.js";
import { createRecaptchaToken } from "@/utils/recaptcha";

export default {
  components: {
    WhatsApp: () => import("./WhatsApp.vue"),
    Wrapper: () => import("../../components/Wrapper.vue"),
    NoNavLayout: () => import("../../layouts/NoNavLayout.vue"),
    Recaptcha: () => import("../../components/Recaptcha.vue"),
    TrialPopup: () => import("../../components/TrialPopup.vue")
  },
  data() {
    return {
      code: null,
      codeId: null,
      timeUntilNextResend: 0,
      verificationError: "",
      finishedRegistration: false,
      clientData: {},
      verifyCounter: 0,
      showAreYouSureNoTrial: false,
      chooseToGoWithTrial: false,
      newPlayerLink: null,
      mode: "whatsapp",
    };
  },
  watch: {
    timeUntilNextResend: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timeUntilNextResend--;
          }, 1000);
        }
      },
      immediate: true,
    },
  },
  computed: {
    loadedAsIframe() {
      return window.self !== window.top;
    }
  },
  methods: {
    ...mapActions(["setToken", 'getPixelId']),
    async login() {
      this.$router.push(`/`);
    },
    async resendCode() {
      this.showAreYouSureNoTrial = false;
      this.chooseToGoWithTrial = true;
      this.clientData.isTrial = true;
      this.clientData.sendingMethod = this.mode;
      const loader = this.$loading.show();
      const googleCaptcha = await createRecaptchaToken();
      try {
        const res = await ApiService.publicApi.sendPhoneVerificationCodeAndCreateLead(
          this.clientData,
          googleCaptcha
        );
        loader.hide();
        if (res.switchClientToSms) {
          this.mode = "sms";
        }
        if (res.alreadyExist) {
          const queryStringParams = Object.fromEntries(
            new URLSearchParams(window.location.search)
          );
          this.$router.push({
            name: "Register",
            params: {
              ...this.clientData,
              alreadyExist: res.alreadyExist,
              byEmail: res.byEmail,
              byPhone: res.byPhone,
              error101: res.error101,
              byUsername: res.byUsername
            },
            query: queryStringParams,
          });
          return;
        }
        this.clientData.leadId = this.clientData.leadId || res.leadId;
        if (res?.codeId) {
          this.codeId = res?.codeId;
          this.switchScreenOnly('sms');
        }
        if (this.mode === "sms") {
          this.timeUntilNextResend = 30;
        }

        this.verificationError = "";
        this.code = null;
      } finally {
        loader.hide();
      }
    },
    async verify() {
      const loader = this.$loading.show();
      this.showAreYouSureNoTrial = false;
      this.verifyCounter++;
      this.clientData.phoneVerificationCode = this.code;
      this.clientData.codeId = this.codeId;

      const googleCaptcha = await createRecaptchaToken();
      this.clientData.googleCaptcha = googleCaptcha;

      const queryStringParams = Object.fromEntries(
        new URLSearchParams(window.location.search)
      );

      try {
        this.clientData.linkId = this.clientData?.linkId || queryStringParams?.linkId;
        this.clientData.sourceId = this.clientData?.sourceId || queryStringParams?.sourceId;
        this.clientData.language = this.$i18n.locale;
        const res = await ApiService.clientsApi.register(this.clientData);

        loader.hide();

        if (res.alreadyExist) {
          this.$router.push({
            name: "Register",
            params: {
              ...this.clientData,
              alreadyExist: res.alreadyExist,
              byEmail: res.byEmail,
              byPhone: res.byPhone,
              error101: res.error101,
              byUsername: res.byUsername
            },
            query: queryStringParams,
          });
          return;
        }
        if (res.phoneVerificationStatus) {
          this.verificationError =
            res.phoneVerificationStatus === "pending"
              ? "Incorrect Code"
              : res.phoneVerificationStatus;
        } else {
          this.finishedRegistration = true;
          await this.getPixelId();
          if (this.loadedAsIframe && queryStringParams?.closeOnSignIn) {
            const queryStringParams = Object.fromEntries(new URLSearchParams(window.location.search));
            window.parent.postMessage("close", queryStringParams?.originTarget);
          } else if (res.token) {
            this.setToken(res.token);
            if (res.shouldRedirect) {
              this.$router.push("Subscriptions");
            }
          }
          if (res.newPlayerLink) {
            this.newPlayerLink = res.newPlayerLink;
          }
        }
      } catch {
        loader.hide();
        this.clientData.captchaErr = true;
        this.$router.push({
          name: "Register",
          params: this.clientData,
          query: queryStringParams,
        });
      }
    },
    async switchToSMS() {
      this.mode = "sms";
      await this.resendCode();
    },
    switchScreenOnly(mode) {
      this.mode = mode;
    },
  },
  async created() {
    const clientData = this.$route.query.clientData;
    if (clientData) {
      const decodedData = atob(clientData);
      this.clientData = JSON.parse(decodedData);
    } else {
      this.clientData = this.$route.params;
    }
    if (!this.clientData.phone || this.clientData.leadId) {
      this.verify();
      return;
    }
    if (this.clientData.isTrial) {
      this.resendCode();
      return;
    }
    if (
      !this.clientData.isTrial &&
      this.clientData.phone &&
      !this.clientData.isLead
    ) {
      this.showAreYouSureNoTrial = true;
    }
  },
};
</script>
<style scoped>
p,
h1,
h2 {
  margin: 0;
  padding: 0;
}

.col {
  min-width: 150px !important;
}

.default-class {
  color: white !important;
}

.verify-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.verify-header {
  font-size: 40px;
  margin-bottom: 20px;
  font-weight: 600;
}

.verify-subtext-item {
  font-size: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.verify-subtext-item2 {
  font-size: 18px;
}

.verify-input-el {
  display: flex;
  width: 100%;
  margin-top: 20px;
  justify-content: center;
}

.verify-input {
  background-color: #27333f;
  border: none;
  width: 75%;
  outline: none;
  color: white;
  font-size: 16px;
  padding: 12px;
  border-radius: 5px 0 0 5px;
  margin: 0;
}

.verify-btn {
  width: 20%;
  color: white;
  background-color: #24d564;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 0 5px 5px 0;
  width: 25%;
  transition: 0.2s all;
}

.verify-btn:hover {
  background-color: #24d565c0;
}

.verify-question {
  margin-top: 40px;
  font-size: 14px;
  color: #5d616a;
}

.verify-sms-btn {
  margin-top: 15px;
  color: white;
  background-color: #ec8f26;
  font-weight: 600;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 7px;
  padding: 10px 20px;
  transition: 0.2s all;
}

.verify-sms-btn:hover {
  background-color: #ec9026c7;
}

.disabled-btn {
  pointer-events: none; 
  background-color: rgb(255, 255, 255, 0.4) !important;
	border: 2px solid rgb(255, 255, 255, 0.1) !important;
}

@media only screen and (max-width: 767px) {
  .verify-header {
    font-size: 26px;
  }

  .verify-subtext-item,
  .verify-subtext-item2 {
    font-size: 14px;
  }

  .verify-question {
    font-size: 12px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
