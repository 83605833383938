/* eslint-disable */
import { apiRequest } from './api.js';

const BASE_URL = '/api/public';

const register = (clientData) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/register`, body: { ...clientData } });

const searchClient = (token) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/searchClient`, body: { token } });
const updateCallMe = (token, dontCallMe) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/updateCallMe`, body: { dontCallMe, token } });

const forgetPassword = (email) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/forgetPassword`, body: { email } });

const generateNewPasswordByMail = (email) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/generateNewPasswordByMail`, body: { email } });

const changePass = (token, newPassword) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/changePass`, body: { newPassword, token } });

const unsubscribe = (clientId, hash, marketingType) =>
	apiRequest({ method: 'get', endpoint: `${BASE_URL}/unsubscribe?id=${clientId}&hash=${hash}&marketingType=${marketingType}` });

const reportLoginPortalSuccess = (token, username) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/reportLoginPortalSuccess`, body: { username, token } });

const syncBcoinTransactions = (token) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/syncBcoinTransactions`, body: { token } });

const getClientMessages = (token) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/getClientMessages`, body: { token, platform: 'portal' } });

const readMessage = (token, messageId) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/readMessage`, body: { token, messageId } });

const changeBirthday = (token, birthDate) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/changeBirthday`, body: { token, birthDate } })

export default { register, searchClient, forgetPassword, generateNewPasswordByMail, changePass, updateCallMe, unsubscribe, reportLoginPortalSuccess, syncBcoinTransactions, getClientMessages, readMessage, changeBirthday };
